import { ROOT_NODE_ID, useBuilderStore } from '../../stores/builder.store';
import { GraphLink, GraphNodeData } from '../indexedDb.helper';
import { findDescendantNodeIds } from './builder-links.helpers';
import {
  makeRecipeIdCustom,
  makeRecipeNameCustom
} from './builder-naming.helpers';
import {
  convertLeafNodeToIntermediateRecipe,
  makeParentsCustomRecipe,
  reloadCurrentRecipe
} from './builder-recipe.helpers';

/**
 * Remove a recipe node
 */
export const removeNode = async (nodeId) => {
  const currentRecipe = makeParentsCustomRecipe(
    nodeId,
    useBuilderStore.getState().currentRecipe
  );
  const nodesToRemove = [
    nodeId,
    ...findDescendantNodeIds(nodeId, currentRecipe.links)
  ];
  const resultingNodes = currentRecipe.nodes.filter(
    (n) => !nodesToRemove.includes(n.id)
  );
  const resultingLinks = currentRecipe.links.filter(
    (l) =>
      !nodesToRemove.includes(l.source) && !nodesToRemove.includes(l.target)
  );
  await useBuilderStore.getState().saveCustomRecipe({
    id: currentRecipe.id,
    name: currentRecipe.name,
    nodes: resultingNodes,
    links: resultingLinks
  });
};

/**
 * Copying this type out of mui internals to prevent direct import of internals
 */
interface TreeViewItemReorderPosition {
  parentId: string | null;
  index: number;
}

/**
 * Respond to a node position change event.
 * Original intended for drag and drop reordering of nodes.
 */
export const respondToNodePositionChange = async (event: {
  itemId: string;
  oldPosition: TreeViewItemReorderPosition;
  newPosition: TreeViewItemReorderPosition;
}) => {
  const newParentId = event.newPosition.parentId
    ? parseInt(event.newPosition.parentId)
    : null;

  const oldParentId = event.oldPosition.parentId
    ? parseInt(event.oldPosition.parentId)
    : null;

  const draggedNodeId = parseInt(event.itemId);

  // Ensure parent is non null
  if (newParentId === null || oldParentId === null) {
    await reloadCurrentRecipe();
    return;
  }

  const hasChildren =
    findDescendantNodeIds(
      newParentId,
      useBuilderStore.getState().currentRecipe.links
    ).length > 0;

  let newRecipeState;

  if (!hasChildren) {
    newRecipeState = droppedOnLeafNode(newParentId, oldParentId, draggedNodeId);
  } else {
    newRecipeState = droppedOnNodeWithChildren(
      newParentId,
      oldParentId,
      draggedNodeId,
      event.newPosition.index
    );
  }

  // Load updated data
  await useBuilderStore
    .getState()
    .loadRecipeFromData(
      newRecipeState.name,
      newRecipeState.id,
      newRecipeState.nodes,
      newRecipeState.links,
      true
    );

  // Remove this if we don't want drag and drop to select the node
  const { updateSelectedRecipe } = useBuilderStore.getState();
  updateSelectedRecipe(draggedNodeId);
};

/**
 * Returns the new recipe state when a node is dropped on another node that has
 * no children.
 * When a node is dragged and dropped on a leaf node we need to create
 * an intermediate recipe node to hold original leaf node and as a sibling the
 * dropped node and its children.
 */
const droppedOnLeafNode = (newParentId, oldParentId, draggedNodeId) => {
  // Mark the parents of the start and end nodes as custom recipe

  const currentRecipe = makeParentsCustomRecipe(
    oldParentId,
    makeParentsCustomRecipe(
      newParentId,
      useBuilderStore.getState().currentRecipe
    ),
    true
  );

  const { nodes, links } = currentRecipe;

  // the item and its children
  const childNodeIds = findDescendantNodeIds(draggedNodeId, links);

  const nodesToAttach = [];
  const otherNodes = [];

  nodes.forEach((n) => {
    if (childNodeIds.includes(n.id) || n.id === draggedNodeId) {
      nodesToAttach.push(n);
    } else {
      otherNodes.push(n);
    }
  });

  const linksToAttach = [];

  links.forEach((link) => {
    if (
      childNodeIds.includes(link.source) ||
      childNodeIds.includes(link.target)
    ) {
      linksToAttach.push(link);
    }
  });

  const { newNodes, newLinks, intermediateNodeId } =
    convertLeafNodeToIntermediateRecipe(
      newParentId,
      draggedNodeId,
      nodesToAttach,
      linksToAttach
    );

  // update the newLinks with existing links
  links.forEach((link) => {
    if (link.target === newParentId) {
      newLinks.push({ source: link.source, target: intermediateNodeId });
    } else if (link.target === draggedNodeId) {
      // we don't want this link anymore
      return;
    } else {
      newLinks.push(link);
    }
  });

  return {
    ...currentRecipe,
    nodes: [...otherNodes, ...newNodes],
    links: newLinks
  };
};

/**
 * Returns the new recipe state when a node is dropped on another node that has
 * children.
 * When a node is dragged and dropped on a node with children we need to add
 * the dropped node to the children but making sure the index realtive to the
 * children is correct.
 */
const droppedOnNodeWithChildren = (
  newParentId,
  oldParentId,
  draggedNodeId,
  newPositionIndex
) => {
  // Mark the parents of the start and end nodes as custom recipe

  const currentRecipe = makeParentsCustomRecipe(
    oldParentId,
    makeParentsCustomRecipe(
      newParentId,
      useBuilderStore.getState().currentRecipe,
      true
    ),
    true
  );
  const { nodes, links } = currentRecipe;

  const draggedNode = nodes.find((node) => node.id === draggedNodeId);

  // Ensure the dragged node exists
  if (!draggedNode) {
    return;
  }

  // Remove old link and add the new link from new parent
  const updatedLinks = [
    ...links.filter((link) => link.target !== draggedNodeId),
    { source: newParentId, target: draggedNodeId }
  ];

  // Find siblings under the new parent, excluding the dragged node
  const siblingsNodes = nodes.filter(
    (node) =>
      node.id !== draggedNodeId &&
      links.some(
        (link) => link.target === node.id && link.source === newParentId
      )
  );

  // ensure new index is within bounds
  const newIndex = Math.min(newPositionIndex, siblingsNodes.length);

  // Create reordered siblings including the dragged node at the new index
  const reorderedSiblings = [
    ...siblingsNodes.slice(0, newIndex),
    draggedNode,
    ...siblingsNodes.slice(newIndex)
  ];

  // Update nodes by excluding the old ones related to the parent and adding reordered siblings
  let updatedNodes = [
    ...nodes.filter(
      (node) =>
        node.id !== draggedNodeId &&
        !siblingsNodes.some((sibling) => sibling.id === node.id)
    ),
    ...reorderedSiblings
  ];

  // Update the links to reflect reordered siblings
  const reorderedLinks = reorderedSiblings.map((node) => ({
    source: newParentId,
    target: node.id
  }));

  let finalLinks = [
    ...updatedLinks.filter(
      (link) => !reorderedSiblings.some((node) => link.target === node.id)
    ),
    ...reorderedLinks
  ];

  // Remove old parent if no children left
  if (finalLinks.every((link) => link.source !== oldParentId)) {
    updatedNodes = updatedNodes.filter((node) => node.id !== oldParentId);
    finalLinks = finalLinks.filter((link) => link.target !== oldParentId);
  }

  return {
    ...currentRecipe,
    nodes: updatedNodes,
    links: finalLinks
  };
};

/**
 * If a node is a custom recipe then it should have at least one non
 * precondition recipe child.
 */
export const checkForDanglingPreconditions = (
  nodes: GraphNodeData[],
  links: GraphLink[]
): number[] => {
  const nodesById = new Map(nodes.map((node) => [node.id, node]));
  const customRecipeNodes = nodes.filter((node) => node.isCustomRecipe);

  return customRecipeNodes
    .map((node) => {
      const children = links
        .filter((link) => link.source === node.id)
        .map((link) => nodesById.get(link.target));

      if (children.some((child) => !child?.isPrecondition)) {
        return null;
      } else {
        return node.id;
      }
    })
    .filter((id) => id !== null) as number[];
};

/**
 * Node IDs must be unique and continuous so this helper will let us know where
 * we last left off at and return the next available ID
 */
export const findNextAvailableId = (nodes: GraphNodeData[] = []) => {
  const ids = nodes.map((node) => node.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
};

/**
 * Duplicates the selected recipe node and its subgraph
 */
export const duplicateRecipeNode = async () => {
  const { isEditable, loadRecipeFromData, update } = useBuilderStore.getState();

  if (!isEditable) {
    return;
  }

  const { nodes, links } = makeParentsCustomRecipe(
    useBuilderStore.getState().selectedRecipeNode?.id,
    useBuilderStore.getState().currentRecipe
  );

  const { selectedRecipeNode } = useBuilderStore.getState();

  if (selectedRecipeNode?.id === ROOT_NODE_ID) {
    return;
  }

  // Find parent node selectedRecipeNode
  const parentNodeId = links.find(
    (link) => link.target === selectedRecipeNode.id
  )?.source;

  // Find the subgraph ids
  const descendants: number[] = findDescendantNodeIds(
    selectedRecipeNode.id,
    links
  );

  let nextAvailableId = findNextAvailableId(nodes);

  // Create the duplicated node with a new ID
  const newSelectedNode = {
    ...selectedRecipeNode,
    id: nextAvailableId
  };

  // Need to keep track of the old and new IDs in the subgraph
  const idMap = new Map<number, number>();
  idMap.set(selectedRecipeNode.id, nextAvailableId);

  nextAvailableId++;

  // Duplicate all descendant nodes but with new IDs
  const newNodes = [newSelectedNode];
  for (const descId of descendants) {
    const originalNode = nodes.find((node) => node.id === descId);
    if (originalNode) {
      const newNode = {
        ...originalNode,
        id: nextAvailableId
      };
      idMap.set(descId, nextAvailableId);
      newNodes.push(newNode);
      nextAvailableId++;
    }
  }

  // Duplicate the links but with updated IDs using the map
  const newLinks = descendants.flatMap((descId) => {
    return links
      .filter((link) => link.source === descId || link.target === descId)
      .map((link) => ({
        source: idMap.get(link.source) || link.source,
        target: idMap.get(link.target) || link.target
      }));
  });

  // Add a link from the original node's parent to the duplicated node
  if (parentNodeId !== undefined) {
    newLinks.push({
      source: parentNodeId,
      target: newSelectedNode.id
    });
  }

  await loadRecipeFromData(
    useBuilderStore.getState().currentRecipe.name,
    useBuilderStore.getState().currentRecipe.id,
    [...nodes, ...newNodes],
    [...links, ...newLinks],
    true
  );

  const { updateSelectedRecipe } = useBuilderStore.getState();
  updateSelectedRecipe(newSelectedNode.id);

  // If the new node has options open the edit options dialog to save a step
  if (newSelectedNode?.val?.options?.length > 0) {
    update({
      showEditRecipeOptionsDialog: true
    });
  }
};

/**
 * adds the nodes and links and adds them to the target node
 */
export const addNodesAndLinksToTargetNode = async (
  nodes,
  links,
  targetId,
  addAsPrecondition,
  addToTop
) => {
  const { isEditable, loadRecipeFromData } = useBuilderStore.getState();

  if (!isEditable) {
    return;
  }

  const { nodes: existingNodes, links: existingLinks } =
    makeParentsCustomRecipe(
      useBuilderStore.getState().selectedRecipeNode?.id,
      useBuilderStore.getState().currentRecipe,
      true
    );

  const { descendantNodeIds, selectedRecipeNode } = useBuilderStore.getState();

  // Create a map to track old IDs to new IDs
  const idMap = new Map<number, number>();
  let nextAvailableId = findNextAvailableId(existingNodes);

  // Duplicate the nodes with new IDs
  const newNodes = nodes.map((node, index) => {
    const newId = nextAvailableId++;
    idMap.set(node.id, newId);
    return {
      ...node,
      id: newId,
      isPrecondition: addAsPrecondition ? index === 0 : node.isPrecondition
    };
  });

  // Duplicate the links with updated source and target IDs
  const newLinks = links.map((link) => ({
    source: idMap.get(link.source) || link.source,
    target: idMap.get(link.target) || link.target
  }));

  const hasChildren = descendantNodeIds.length > 0;
  let updatedNodes, updatedLinks;

  if (hasChildren) {
    // If the target node has children, just add the new nodes and links
    if (addToTop) {
      updatedNodes = [...newNodes, ...existingNodes];
      updatedLinks = [
        { source: targetId, target: newNodes[0].id },
        ...newLinks,
        ...existingLinks
      ];
    } else {
      updatedNodes = [...existingNodes, ...newNodes];
      updatedLinks = [
        ...existingLinks,
        ...newLinks,
        { source: targetId, target: newNodes[0].id }
      ];
    }
  } else {
    // If the target node doesn't have children, create an intermediate node
    const intermediateNodeId = nextAvailableId++;
    const intermediateNode = {
      id: intermediateNodeId,
      name: makeRecipeNameCustom(selectedRecipeNode?.name),
      isCustomRecipe: true,
      isPrecondition: selectedRecipeNode?.isPrecondition,
      val: {
        name: makeRecipeNameCustom(selectedRecipeNode?.name),
        id: makeRecipeIdCustom(selectedRecipeNode?.val?.id, intermediateNodeId)
      }
    };

    const newIntermediateLinks = [
      { source: intermediateNodeId, target: targetId },
      { source: intermediateNodeId, target: newNodes[0].id },
      ...newLinks
    ];

    existingLinks.forEach((link) => {
      if (link.target !== targetId) {
        newIntermediateLinks.push(link);
      } else {
        newIntermediateLinks.push({
          source: link.source,
          target: intermediateNodeId
        });
      }
    });

    updatedNodes = [...existingNodes, ...newNodes, intermediateNode];
    updatedLinks = newIntermediateLinks;
  }

  // Load the updated recipe data
  await loadRecipeFromData(
    useBuilderStore.getState().currentRecipe.name,
    useBuilderStore.getState().currentRecipe.id,
    updatedNodes,
    updatedLinks,
    true
  );
};
