import { ApolloError } from '@apollo/client';
import { Duration } from 'date-fns';

import {
  Option,
  RecipeRunState,
  RecipeRunSummary
} from '../__generated__/apollo-hooks';
import { parseISODuration } from './time.helper';

export const isRunComplete = (state: RecipeRunState): boolean =>
  state === 'FINISHED' || state === 'CANCELED';

const DEFAULT_DURATION_OBJECT = {
  years: 0,
  months: 0,
  weeks: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
};
export const summarizeTimeSavings = (
  results: Pick<RecipeRunSummary, 'timeSavings'>[]
): Duration => {
  const duration = results
    .filter(Boolean)
    .map((s) =>
      s.timeSavings ? parseISODuration(s.timeSavings) : DEFAULT_DURATION_OBJECT
    )
    .reduce(
      (a, b) => {
        const val = {};
        Object.keys(a).forEach((k) => {
          return (val[k] = Number(a[k]) + (b[k] ? Number(b[k]) : 0));
        });
        return val;
      },
      {
        years: 0,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    );

  if (duration.seconds >= 60) {
    duration.minutes += Math.floor(duration.seconds / 60);
    duration.seconds = duration.seconds % 60;
  }
  if (duration.minutes >= 60) {
    duration.hours += Math.floor(duration.minutes / 60);
    duration.minutes = duration.minutes % 60;
  }

  return duration;
};

export const recipeNotFoundError = (
  id: string,
  error: ApolloError
): string | false => {
  const recipeNotFoundErrors = [
    `Recipe run ${id} does not exist.`,
    `Recipe run not found: ${id} (or recipe not found for run).`
  ];

  for (let i = 0; i < recipeNotFoundErrors.length; i++) {
    if (error?.message.includes(recipeNotFoundErrors[i])) {
      return recipeNotFoundErrors[i];
    }
  }

  return false;
};

export const safePrintRecipeOptionValue = (optionValue: unknown): string => {
  if (Array.isArray(optionValue)) {
    return optionValue.join(', ');
  }

  return String(optionValue).replace(',', ', ');
};

export const isValidOptionValue = ({ value }: Option) =>
  value !== null &&
  value !== '' &&
  value !== undefined &&
  !(Array.isArray(value) && value?.length === 0);
