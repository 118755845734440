import { detectOSInBrowser } from '@bonsai-components/detect-os';
import { TabContext } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Skeleton,
  Stack,
  SvgIcon,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { bindDialog } from 'material-ui-popup-state';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSession } from 'next-auth/react';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react';
import { MarkdownString } from '../../../@types';
import { DOCUMENTATION_SITE_URL } from '../../../constants/general';
import { ConfigurationContext } from '../../../contexts/config.context';
import {
  DownloadsContext,
  ManifestKind
} from '../../../contexts/downloads.context';
import { capitalize } from '../../../helpers/string.helper';
import { CodeSnippet } from '../../code-snippet/code-snippet.component';
import { SpacedDivider } from '../../styled-components/layouts/layouts.styled';
import { ModerneTabPanel } from '../../styled-components/tabs/tabs.styled';
import { ClosableDialogTitle } from '../../utilities/dialog/closable-dialog-title.component';
import { ModerneMarkdown } from '../../utilities/markdown/markdown.component';
import { Link } from '../../utilities/moderne-link/moderne-link.component';
import { NavigationMenuItem } from '../account-menu.styled';
import { DISTRIBUTIONS } from './moderne-cli-distributions';
import {
  Distribution,
  InstallKind,
  PackageChannel,
  PackageInstallCommand,
  PackageInstallCommandResult,
  determineOperatingSystem,
  getDownloadUrl,
  getFileName,
  useLatestVersion
} from './moderne-cli.helpers';

type CliPackageInstallationCommand = PackageInstallCommand & {
  fileExtension: string;
  operatingSystem: string;
};
export const ModerneCli: FunctionComponent = () => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'moderne-cli-dialog'
  });
  const { getApiGatewayUrl } = useContext(ConfigurationContext);
  const { data: session } = useSession();
  const { manageDownload } = useContext(DownloadsContext);
  const [currentInstallCommand, setCurrentInstallCommand] = useState<string>();
  const [environment, setEnvironment] = useState<PackageChannel>('stable');
  const OS = detectOSInBrowser();
  const apiGatewayUrl = getApiGatewayUrl();

  const installCommands = DISTRIBUTIONS.reduce<
    Array<CliPackageInstallationCommand>
  >((acc, dist) => {
    return acc.concat(
      dist.installCommands.map((cmd) => ({
        ...cmd,
        operatingSystem: dist.key,
        fileExtension: dist.fileExtension
      }))
    );
  }, []);

  const handleDownload = (
    distribution: Distribution,
    environment: PackageChannel
  ) => {
    const url = getDownloadUrl({
      baseUrl: apiGatewayUrl,
      operatingSystem: distribution.key.toLowerCase(),
      environment
    });
    const fileName = getFileName({
      fileExtension: distribution.fileExtension
    });

    manageDownload({
      id: `moderne-cli-${distribution.key}`,
      kind: ManifestKind.AUTHENTICATED,
      sourcePath: url,
      fileName,
      onDownloadEnd: () => popupState.close()
    });
  };

  const {
    loading,
    data: releaseData,
    error
  } = useLatestVersion({
    apiGatewayUrl,
    accessToken: session?.accessToken,
    operatingSystem: determineOperatingSystem(OS)
  });

  const hasReleases =
    !loading && releaseData && Object.keys(releaseData).length > 0;

  /**
   * On mount use the detected OS to set the value
   */
  useEffect(() => {
    const bestGuessCommand = DISTRIBUTIONS.find((dist) =>
      dist.key.startsWith(OS)
    );
    if (bestGuessCommand) {
      setCurrentInstallCommand(bestGuessCommand.key);
    }
  }, [OS, popupState.isOpen]);

  const handleCommandChange = (_event, newValue: string) => {
    setCurrentInstallCommand(newValue);
  };

  const handleOpenDialog = (key) => {
    setEnvironment(key as PackageChannel);
    popupState.open();
  };

  return (
    <React.Fragment>
      {hasReleases && !error && (
        <NavigationMenuItem sx={{ pl: 2 }} {...bindTrigger(popupState)}>
          <Stack>
            Moderne CLI
            <Stack
              sx={{
                alignItems: 'start'
              }}>
              {Object.entries(releaseData).map(([key, value]) => (
                <Button
                  variant="text"
                  key={key}
                  data-testid="account-menu-moderne-cli"
                  aria-label={'Moderne CLI'}
                  onClick={() => handleOpenDialog(key)}>
                  <Typography variant="caption">
                    {capitalize(key)} ({value})
                  </Typography>
                </Button>
              ))}
            </Stack>
          </Stack>
        </NavigationMenuItem>
      )}
      {loading && !error && (
        <Skeleton variant="rectangular" width="100%" height="3rem" />
      )}
      {error && <Alert severity="warning">Unable to find CLI releases.</Alert>}
      <Dialog
        maxWidth="md"
        {...bindDialog(popupState)}
        aria-labelledby="moderne-cli-dialog-title"
        aria-describedby="moderne-cli-dialog-description">
        <ClosableDialogTitle
          title={
            hasReleases
              ? `Moderne CLI - ${capitalize(environment)} - ${
                  releaseData[environment]
                }`
              : 'Moderne CLI'
          }
          onClose={popupState.close}
        />
        <DialogContent>
          <Typography variant="bodySm" paragraph>
            The Moderne CLI is a multi-repository developer experience for
            automated code remediation. From the CLI, you can build and publish
            LSTs, access and run recipes, generate data tables, and easily test
            recipes working with the Moderne IDE plugin.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{
              gap: 2,
              justifyContent: 'center'
            }}>
            {DISTRIBUTIONS.map((dist) => (
              <Button
                key={dist.key}
                variant="outlined"
                color="secondary"
                fullWidth={false}
                disabled={!releaseData}
                startIcon={
                  <SvgIcon
                    component={dist.icon}
                    inheritViewBox
                    fontSize="small"
                  />
                }
                onClick={() => handleDownload(dist, environment)}>
                Download {dist.label}
              </Button>
            ))}
          </Stack>
          <SpacedDivider>or through the command line</SpacedDivider>
          <TabContext
            value={currentInstallCommand ?? installCommands.at(0).key}>
            <Tabs
              variant="scrollable"
              value={currentInstallCommand ?? installCommands.at(0).key}
              onChange={handleCommandChange}>
              {installCommands.map((cmd) => (
                <Tab key={cmd.key} label={cmd.label} value={cmd.key} />
              ))}
            </Tabs>

            {installCommands.map((cmd, idx) => (
              <ModerneTabPanel
                value={(cmd.key ?? idx === 0) ? cmd.key : undefined}
                key={cmd.key}>
                <ModerneCliInstallationCommand
                  label={cmd.label}
                  description={cmd.description}
                  command={cmd.command({
                    uri: getDownloadUrl({
                      baseUrl: apiGatewayUrl,
                      operatingSystem: cmd.operatingSystem,
                      environment
                    }),
                    token: session?.accessToken as string,
                    fileName: getFileName({
                      fileExtension: cmd.fileExtension
                    }),
                    version: releaseData?.[environment],
                    environment
                  })}
                />
              </ModerneTabPanel>
            ))}
          </TabContext>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'flex-start'
          }}>
          <FormControlLabel
            sx={{ marginLeft: '1rem' }}
            control={<Switch size="small" />}
            onChange={(_e, checked) => {
              setEnvironment(checked ? 'staging' : 'stable');
            }}
            checked={environment === 'staging'}
            label="Staging release"
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

type ModerneCliInstallationCommandProps = {
  label: string;
  description?: MarkdownString;
  command: PackageInstallCommandResult;
};

const ModerneCliInstallationCommand: FunctionComponent<
  ModerneCliInstallationCommandProps
> = ({ label, description, command: cmd }) => {
  if (cmd.kind === InstallKind.MARKDOWN) {
    return (
      <Card variant="outlined">
        <CardContent>
          <ModerneMarkdown
            variant="contained"
            overrides={{
              code: {
                component: CodeSnippet,
                props: {
                  copyButtonPlacement: 'top'
                }
              }
            }}>
            {cmd.command}
          </ModerneMarkdown>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <CodeSnippet
        key={label}
        copyButtonPlacement="top"
        slotProps={{
          copyButton: {
            text: cmd.command({ maskToken: false })
          }
        }}
        slots={{
          footer: description ? (
            <Box
              sx={{
                m: 1
              }}>
              <Typography variant="bodySm">
                <ModerneMarkdown variant="contained">
                  {description}
                </ModerneMarkdown>
              </Typography>
            </Box>
          ) : (
            <Alert severity="info">
              <Typography variant="caption">
                Hint: you can also use a{' '}
                <Link
                  external
                  href={`${DOCUMENTATION_SITE_URL}/user-documentation/moderne-platform/how-to-guides/create-api-access-tokens/`}>
                  Moderne PAT
                </Link>{' '}
                for the <code>Authorization</code> header.
              </Typography>
            </Alert>
          )
        }}>
        {cmd.command({ maskToken: true })}
      </CodeSnippet>
    );
  }
};
