import {
  createTheme,
  PaletteMode,
  PaletteOptions,
  responsiveFontSizes,
  Theme,
  ThemeOptions
} from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { DataGridProComponents } from '@mui/x-data-grid-pro/themeAugmentation/props';

import { PureFunction } from '@bonsai-components/utility-types';
import { Property } from 'csstype';
import { DOHERTY_THRESHOLD, PAGE_SIZE_DEFAULT } from '../constants/general';
import { ColumnsIcon, FilterListIcon, SearchIcon } from '../icons/icons';
import { ModMuiCheckbox } from './component-defaults/mod-mui-checkbox';

const FONT_FAMILY_SYSTEM = `-apple-system, BlinkMacSystemFont, "Segoe UI",
Helvetica, Arial, sans-serif,
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const FONT_FAMILY_MONO =
  'Menlo,ui-monospace,SFMono-Regular,Monaco,Consolas,Liberation Mono,Courier New,monospace';

// 3rd party colors
export const BITBUCKET_BLUE = '#2584FF';
export const AZURE_BLUE = '#106BCB';
export const GITLAB_ORANGE = '#FA6C26';
export const GRAPHQL_PINK = '#D31196';
export const JFROG_GREEN = '#40c048';

export const moderneColors = {
  grey: {
    100: '#F8F9FA',
    150: '#EDEFEF', // source GitHub markdown background
    200: '#E9ECEF',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    600: '#6C757D',
    700: '#495057',
    800: '#343A40',
    900: '#272C31'
  },
  red: {
    cherry: '#FF3332',
    main: '#FF3332',
    100: '#FFE5EB',
    200: '#FFB2C2',
    300: '#FF7F99',
    400: '#FF4C70',
    500: '#E3163F',
    600: '#AA112F',
    700: '#710B20',
    800: '#390610'
  },
  yellow: {
    golden: '#ECB91E',
    main: '#ECB91E',
    100: '#FFF9E6',
    200: '#FFEFBF',
    300: '#FFE499',
    400: '#FFDB73',
    500: '#FFCC33',
    600: '#CC9926',
    700: '#996619',
    800: '#66440C'
  },
  green: {
    main: '#87FF9C',
    100: '#F0FFF4',
    200: '#DCFFE4',
    300: '#BEF5CB',
    400: '#A2E669',
    500: '#3ED68F',
    600: '#28A745',
    700: '#52985D',
    800: '#1E7E34'
  },
  blue: {
    iceBlue: '#00FFF0', // originally used for login orb in dark mode
    main: '#2E42FF',
    100: '#F6FAFD',
    200: '#B9C0FF',
    300: '#8B96FF', // required for the ColorPalette type
    400: '#2E42FF', // required for the ColorPalette type
    500: '#2C36FB',
    600: '#323A85',
    700: '#141D71',
    800: '#0A0F39'
  },
  indigo: {
    midnight: '#00193D',
    main: '#00193D',
    50: '#F4F7FB',
    100: '#E3E5E9',
    150: '#C4C4C4',
    200: '#AAB2BE',
    300: '#717F93',
    400: '#394C67',
    450: '#324054',
    500: '#121214',
    700: '#000B1B',
    800: '#00060D'
  },
  violet: {
    darkLavender: '#D9D8E8',
    paleLavender: '#F1EEFF',
    periwinkle: '#7390CB',
    main: '#7390CB',
    100: '#F3F4FF',
    200: '#E0E3FF',
    300: '#CCD1FF',
    400: '#B8BFFF',
    500: '#9DA7FF',
    600: '#7F8EFF',
    700: '#6065FF',
    800: '#7B61FF'
  },
  roseate: {
    recipeRunPurple: '#9A25BB',
    magenta: '#DB4197',
    main: '#DB4197',
    100: '#F7E7F2',
    200: '#F1C5E4',
    300: '#EB9ED6',
    400: '#E576C8',
    500: '#E04EB9',
    600: '#B83D94',
    700: '#912C6F',
    800: '#691B4A'
  },
  black: {
    100: '#1E1E1E',
    800: '#121212'
  }
} as const;

export const textColor =
  (emphasis: 'high' | 'medium' = 'medium') =>
  (theme: Theme): string => {
    if (emphasis === 'high') {
      return theme.palette.mode === 'light'
        ? theme.palette.indigo.main
        : theme.palette.grey[400];
    }

    return theme.palette.mode === 'light'
      ? theme.palette.grey[700]
      : theme.palette.grey[500];
  };

export const backgroundColor =
  (emphasis: 'high' | 'medium' = 'medium') =>
  (theme: Theme): string => {
    if (emphasis === 'high') {
      return theme.palette.mode === 'light'
        ? theme.palette.common.white
        : theme.palette.black[800];
    }

    return theme.palette.mode === 'light'
      ? theme.palette.grey[200]
      : theme.palette.black[100];
  };

const lightPalette = {
  background: {
    default: moderneColors.grey[100],
    paper: '#FFFFFF'
  },
  default: {},
  primary: {
    main: moderneColors.blue.main,
    dark: moderneColors.blue[600]
  },
  secondary: {
    main: moderneColors.grey['900']
  },
  text: {
    primary: moderneColors.grey['800'],
    secondary: moderneColors.indigo.main
  },
  error: {
    main: moderneColors.red.cherry
  },
  info: {
    main: moderneColors.blue['400']
  },
  warning: {
    main: moderneColors.yellow.main
  },
  success: {
    main: moderneColors.green['700']
  },
  diffRemoval: {
    main: moderneColors.red['200'],
    light: moderneColors.red['100']
  },
  diffAddition: {
    main: moderneColors.green['200'],
    light: moderneColors.green['100']
  },
  diffHeader: {
    main: moderneColors.blue['100']
  }
};

const darkPalette = {
  background: {
    default: moderneColors.black[100],
    paper: moderneColors.black[800]
  },
  default: {
    main: moderneColors.grey[500]
  },
  primary: {
    main: moderneColors.indigo[100]
  },
  secondary: {
    main: moderneColors.grey[200]
  },
  text: {
    primary: moderneColors.grey[400],
    secondary: moderneColors.grey[600]
  },
  error: {
    main: moderneColors.red.main
  },
  info: {
    main: moderneColors.blue[200]
  },
  warning: {
    main: moderneColors.yellow.main
  },
  diffRemoval: {
    main: '#4C1B1D',
    light: '#2A1518'
  },
  diffAddition: {
    main: '#183C1F',
    light: '#0F2117'
  },
  diffHeader: {
    main: '#111C32'
  }
};

const getPalette = (mode: PaletteMode): PaletteOptions => {
  if (mode === 'dark') {
    return {
      mode,
      ...darkPalette,
      ...moderneColors
    };
  } else {
    return {
      mode,
      ...lightPalette,
      ...moderneColors
    };
  }
};

const getMuiXThemeComponentConfiguration = (): DataGridProComponents => ({
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper
      }),
      filterForm: {
        alignItems: 'flex-end'
      },
      columnHeaderTitle: {
        textOverflow: 'clip',
        whiteSpace: 'break-spaces',
        lineHeight: 1.2
      },
      toolbarContainer: ({ theme }) => ({
        margin: theme.spacing(1),
        padding: 0,
        justifyContent: 'space-between'
      }),
      cell: {
        minHeight: 36,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 'inherit',
        overflow: 'unset',
        textOverflow: 'unset'
      },
      overlay: {
        pointerEvents: 'auto'
      }
    },
    defaultProps: {
      density: 'compact',
      /**
       * DataGridPro has pagination off by default
       * Enable to prevent inadvertent performance issues.
       * Our `PaginatedDataGrid` will supersede these props for server-side
       * pagination.
       */
      pagination: true,
      paginationMode: 'client',
      pageSizeOptions: [PAGE_SIZE_DEFAULT],
      sortingOrder: ['asc', 'desc'],
      disableRowSelectionOnClick: true,
      disableColumnSelector: false,
      disableDensitySelector: true,
      disableColumnReorder: true,
      filterDebounceMs: DOHERTY_THRESHOLD,
      slots: {
        quickFilterIcon: SearchIcon,
        openFilterButtonIcon: FilterListIcon,
        columnMenuManageColumnsIcon: ColumnsIcon,
        columnMenuFilterIcon: FilterListIcon,
        columnSelectorIcon: ColumnsIcon
      }
    }
  }
});

type ModerneThemeConfiguration = {
  mode: PaletteMode;
  customFontFamily?: string;
};

const getThemeConfiguration: PureFunction<
  ModerneThemeConfiguration,
  ThemeOptions
> = ({ mode, customFontFamily }) => ({
  palette: getPalette(mode),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontFamily: customFontFamily || FONT_FAMILY_SYSTEM,
    fontFamilyMonospace: FONT_FAMILY_MONO,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    h1: {
      fontSize: '1.875rem', // 30px,
      lineHeight: 1.3, // 36px
      fontWeight: 400
    },
    h2: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.2, // 32px
      fontWeight: 400
    },
    h3: {
      fontSize: '1.25rem', // 20px
      lineHeight: 1.4, //28px
      fontWeight: 400
    },
    h4: {
      fontSize: '1.125rem', // 18px
      lineHeight: 1.5, // 27px
      fontWeight: 400
    },
    h5: undefined,
    h6: undefined,
    body: {
      fontSize: '1rem', // 18px
      fontWeight: 400
    },
    bodySm: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400
    },
    // deprecated
    body1: {
      fontSize: '1rem' // 16px,
    },
    // deprecated
    body2: {
      fontSize: '0.875rem' // 14px,
    }
  },
  components: {
    ...getMuiXThemeComponentConfiguration(),
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body: 'p',
          bodySm: 'p'
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 100,
          textTransform: 'none'
        }
      }
    },
    MuiList: {
      defaultProps: {
        dense: true,
        disablePadding: true
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h4'
      }
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
        padding: 'none'
      }
    },
    MuiTabs: {
      defaultProps: {
        textColor: 'secondary'
      },
      styleOverrides: {
        root: {
          minHeight: 'auto'
        }
      }
    },
    MuiTab: {
      defaultProps: {
        iconPosition: 'start'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 'auto',
          textTransform: 'none',
          padding: theme.spacing(1, 2)
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor(
            'high'
          ) as unknown as Property.BackgroundColor
        }
      }
    },
    MuiCollapse: {
      defaultProps: {
        timeout: 'auto',
        appear: true,
        mountOnEnter: true,
        unmountOnExit: true
      }
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      }
    },
    MuiCheckbox: ModMuiCheckbox
  }
});

type ModerneTheme = {
  mode?: PaletteMode;
  customPalette?: Partial<PaletteOptions>;
  customFontFamily?: string;
};

export const createModerneTheme: PureFunction<ModerneTheme, Theme> = ({
  mode = 'light',
  customPalette = {},
  customFontFamily
}) => {
  return responsiveFontSizes(
    createTheme(
      getThemeConfiguration({ mode, customFontFamily }),
      { palette: customPalette },
      {
        factor: 4
      }
    )
  );
};
