import { RecipeDetailsFragment } from '../../__generated__/apollo-hooks';
import { transformRecipeToNodesAndLinks } from '../../components/recipe-topology-viewer/recipe-topology.helper';
import { ROOT_NODE_ID, useBuilderStore } from '../../stores/builder.store';
import { BuilderRecipe, GraphLink, GraphNodeData } from '../indexedDb.helper';

import {
  cleanLinks,
  findDescendantNodeIds,
  findParentNodeIDs
} from './builder-links.helpers';
import {
  makeRecipeIdCustom,
  makeRecipeNameCustom
} from './builder-naming.helpers';
import { findNextAvailableId } from './builder-nodes.helpers';

/**
 * In the case where we are creating a whole new recipe node (not moving an
 * existing node) we need to offset the new node IDs by 1 to avoid collisions.
 */
const OFFSET = 1;

/**
 * Create new intermediate node that will hold the leaf node and a new recipe
 * including the children of the new recipe.
 */
export const convertLeafNodeToIntermediateRecipe = (
  originalLeafNodeId: number,
  newRecipeId: number,
  nodesToAttach: GraphNodeData[],
  linksToAttach: GraphLink[]
) => {
  const currentRecipe = useBuilderStore.getState().currentRecipe;
  const originalLeafNode = currentRecipe.nodes.find(
    (node) => node.id === originalLeafNodeId
  );
  const intermediateNodeId =
    findNextAvailableId(currentRecipe.nodes) + linksToAttach.length + OFFSET;

  const intermediateNode = {
    id: intermediateNodeId,
    name: makeRecipeNameCustom(originalLeafNode?.name),
    isCustomRecipe: true,
    isPrecondition: originalLeafNode?.isPrecondition,
    val: {
      name: makeRecipeNameCustom(originalLeafNode?.name),
      id: makeRecipeIdCustom(originalLeafNode?.val?.id, intermediateNodeId)
    }
  };

  // Connect intermediate node to original leaf node and new recipe node
  const newLinks = [
    { source: intermediateNodeId, target: originalLeafNode?.id },
    { source: intermediateNodeId, target: newRecipeId },
    ...linksToAttach
  ];

  return {
    intermediateNodeId,
    newNodes: [...nodesToAttach, intermediateNode],
    newLinks: newLinks
  };
};

export const addRecipeDetailsFragmentToNode = (
  currentRecipe: BuilderRecipe,
  recipe: RecipeDetailsFragment,
  nodeId: number,
  isPrecondition?: boolean,
  /**
   * When adding to a recipe with children add to the top or the bottom
   */
  addToTop?: boolean
) => {
  const descendantNodeIds = findDescendantNodeIds(nodeId, currentRecipe?.links);
  const nextAvailableId = findNextAvailableId(currentRecipe?.nodes);

  const { nodes, links } = transformRecipeToNodesAndLinks(
    recipe,
    nextAvailableId,
    isPrecondition
  );

  const hasChildren = descendantNodeIds.length > 0;

  let newRecipeState;

  if (hasChildren || nodeId === ROOT_NODE_ID) {
    if (addToTop) {
      newRecipeState = {
        ...currentRecipe,
        nodes: [...nodes, ...currentRecipe.nodes],
        links: [
          { source: nodeId, target: nextAvailableId },
          ...links,
          ...currentRecipe.links
        ]
      };
    } else {
      newRecipeState = {
        ...currentRecipe,
        nodes: [...currentRecipe.nodes, ...nodes],
        links: [
          ...currentRecipe.links,
          ...links,
          { source: nodeId, target: nextAvailableId }
        ]
      };
    }
  } else {
    const { newNodes, newLinks, intermediateNodeId } =
      convertLeafNodeToIntermediateRecipe(
        nodeId,
        nextAvailableId,
        nodes,
        links
      );

    // update the newLinks with existing links
    currentRecipe.links.forEach((link) => {
      if (link.target !== nodeId) {
        newLinks.push(link);
      } else {
        newLinks.push({ source: link.source, target: intermediateNodeId });
      }
    });

    newRecipeState = {
      ...currentRecipe,
      nodes: [...currentRecipe.nodes, ...newNodes],
      links: cleanLinks(newLinks)
    };
  }

  return {
    newRecipeState,
    nextAvailableId
  };
};

export const makeParentsCustomRecipe = (
  id: number,
  currentRecipe,
  includeSelf = false
): BuilderRecipe => {
  const allParents = findParentNodeIDs(id, currentRecipe.links);
  const newNodes = currentRecipe.nodes.map((n) => {
    if (allParents.includes(n.id) || (includeSelf && n.id === id)) {
      return {
        id: n.id,
        name: n.isCustomRecipe ? n.name : makeRecipeNameCustom(n.name),
        isCustomRecipe: true,
        isPrecondition: n.isPrecondition,
        val: {
          ...n?.val,
          name: n.isCustomRecipe ? n.name : makeRecipeNameCustom(n.name),
          id: n.isCustomRecipe
            ? n?.val?.id
            : makeRecipeIdCustom(n?.val?.id, n.id)
        }
      };
    }
    return n;
  });
  const newRecipe = {
    ...currentRecipe,
    nodes: newNodes
  };
  return newRecipe;
};

export const updateSelectedNodeOptions = async (
  options: RecipeDetailsFragment['options']
) => {
  const { selectedRecipeNode, descendantNodeIds } = useBuilderStore.getState();
  const selectedRecipeNodeId = selectedRecipeNode.id;

  const currentRecipe = makeParentsCustomRecipe(
    selectedRecipeNodeId,
    useBuilderStore.getState().currentRecipe
  );

  const hasChildren = descendantNodeIds.length > 0;
  const newNodes = currentRecipe.nodes.map((n) => {
    if (n.id === selectedRecipeNodeId) {
      if (hasChildren) {
        return {
          id: n.id,
          name: n.name,
          isCustomRecipe: true,
          isPrecondition: n.isPrecondition,
          val: {
            ...n?.val,
            id: makeRecipeIdCustom(n?.val?.id, n.id),
            name: makeRecipeNameCustom(n?.val?.name),
            options
          }
        };
      }
      // Is a leaf so it is a standard recipe and does not need a custom name
      return {
        id: n.id,
        name: n.name,
        isCustomRecipe: false,
        isPrecondition: n.isPrecondition,
        val: {
          ...n?.val,
          options
        }
      };
    }
    return n;
  });

  await useBuilderStore.getState().saveCustomRecipe({
    id: currentRecipe.id,
    name: currentRecipe.name,
    nodes: newNodes,
    links: currentRecipe.links
  });
  // reselect the recipe node so user doesn't lose context
  await useBuilderStore.getState().updateSelectedRecipe(selectedRecipeNodeId);
};

export const updateCurrentRecipeDetails = async (
  name,
  id,
  description,
  nodeId = ROOT_NODE_ID
) => {
  const isNestedRecipe = nodeId !== ROOT_NODE_ID;
  const currentRecipe = useBuilderStore.getState().currentRecipe;
  if (!currentRecipe) {
    return;
  }
  const newRecipe = {
    // keep original id/name for root if we are modifying a nested recipe
    id: isNestedRecipe ? currentRecipe.id : id,
    name: isNestedRecipe ? currentRecipe.name : name,
    nodes: currentRecipe?.nodes.map((n) => {
      if (n.id === nodeId) {
        return {
          id: n.id,
          name,
          isCustomRecipe: true,
          isPrecondition: n.isPrecondition,
          val: {
            ...n?.val,
            name,
            id,
            description
          }
        };
      }
      return n;
    }),
    links: currentRecipe?.links
  };

  await useBuilderStore
    .getState()
    .saveCustomRecipe(
      newRecipe,
      isNestedRecipe || (id === currentRecipe.id && !isNestedRecipe)
    );
};

export const reloadCurrentRecipe = async () => {
  const { currentRecipe, loadCustomRecipe } = useBuilderStore.getState();
  await loadCustomRecipe(currentRecipe?.id);
};
