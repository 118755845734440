import { ApolloContextValue } from '@apollo/client';
import { PureFunction } from '@bonsai-components/utility-types';
import { Document, stringify } from 'yaml';
import {
  RecipeDetailDocument,
  RecipeDetailsFragment
} from '../../__generated__/apollo-hooks';
import { TopologyRecipeLike } from '../../components/recipe-topology-viewer/recipe-topology.helper';
import { mapRecipeDetailsFragmentToDisplay } from '../../helpers/recipe-builder.helpers';
import { GraphLink, GraphNodeData } from '../indexedDb.helper';

type ParsedYamlDocAsJSON = {
  type: string;
  name: string;
  displayName: string;
  description: string;
  tags?: string[];
  recipeList: ParsedYamlRecipeListItem[];
  preconditions?: ParsedYamlRecipeListItem[];
};

type ParsedYamlRecipeListItem =
  | string
  | {
      [key: string]: {
        [key: string]: string | string[] | boolean | number | null | undefined;
      };
    };

function isParsedYamlDocAsJSON(
  parsedYamlDoc: ParsedYamlDocAsJSON | TopologyRecipeLike
): parsedYamlDoc is ParsedYamlDocAsJSON {
  return Object.hasOwn(parsedYamlDoc, 'displayName');
}

function isRecipeFragmentDetail(
  recipe: ParsedYamlRecipeListItem | RecipeDetailsFragment
): recipe is RecipeDetailsFragment {
  return typeof recipe === 'object' && Object.hasOwn(recipe, 'id');
}

/**
 * The parsed yaml will look something like this:
 * {
 *   "type": "specs.openrewrite.org/v1beta/recipe",
 *   "name": "com.myuser.recipes.MyRecipe",
 *   "displayName": "My custom recipe",
 *   "description": "Fix all the things.",
 *   "recipeList": [
 *     {
 *       "org.openrewrite.java.ReorderMethodArguments": {
 *         "methodPattern": "org.junit.Assume assume*(String, boolean)",
 *         "newParameterNames": "b,message",
 *         "oldParameterNames": "message,b"
 *       }
 *     },
 *   "org.openrewrite.java.testing.junit5.IgnoreToDisabled"
 * }
 */

export const transformMultipleYamlDocumentsToBuilderV2Recipe: PureFunction<
  {
    parsedYamlDocuments: Document.Parsed[];
    apolloContext: ApolloContextValue;
  },
  Promise<{
    name: string;
    id: string;
    nodes: GraphNodeData[];
    links: GraphLink[];
  }>
> = async ({ parsedYamlDocuments, apolloContext }) => {
  const recipes = parsedYamlDocuments.map((doc) => doc.toJSON());
  const rootRecipe = findRootRecipe(recipes);
  const { nodes, links } = await transformYamlRecipeToNodesAndLinks(
    recipes,
    rootRecipe,
    apolloContext
  );
  return {
    name: rootRecipe?.displayName,
    id: rootRecipe?.name,
    nodes,
    links
  };
};

const findRootRecipe = (
  recipes: ParsedYamlDocAsJSON[]
): ParsedYamlDocAsJSON => {
  const allDependencies = new Set<string>();
  recipes.forEach((recipe) => {
    recipe?.recipeList?.forEach((dependency) => {
      if (typeof dependency === 'string') {
        allDependencies.add(dependency);
      } else {
        allDependencies.add(Object.keys(dependency)[0]);
      }
    });
    recipe?.preconditions?.forEach((precondition) => {
      if (typeof precondition === 'string') {
        allDependencies.add(precondition);
      } else {
        allDependencies.add(Object.keys(precondition)[0]);
      }
    });
  });
  return recipes.find((recipe) => !allDependencies.has(recipe.name));
};

const getRecipeFragment = async (
  recipeId: string,
  apolloContext
): Promise<RecipeDetailsFragment> => {
  return apolloContext.client
    .query({
      query: RecipeDetailDocument,
      variables: { recipeId },
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    })
    .then((response) => {
      if (!response?.data && response?.errors?.length > 0) {
        throw new Error(response?.errors[0]?.message);
      }
      return response?.data?.recipe;
    })
    .catch((e) => {
      throw new Error(e.message);
    });
};

const transformYamlRecipeToNodesAndLinks = async (
  customRecipes: ParsedYamlDocAsJSON[],
  rootRecipe: ParsedYamlDocAsJSON,
  apolloContext: ApolloContextValue
): Promise<{
  nodes: GraphNodeData[];
  links: GraphLink[];
}> => {
  const nodes: GraphNodeData[] = [];
  const links: GraphLink[] = [];
  let uniqueId = 0;

  async function traverse(
    recipe: ParsedYamlDocAsJSON | TopologyRecipeLike,
    parentId?: number,
    isPrecondition = false
  ) {
    const nodeId = uniqueId++;
    const node: GraphNodeData = {
      id: nodeId,
      name: '',
      val: {
        id: '',
        name: '',
        description: '',
        tags: [],
        totalRecipes: 0,
        options: []
      },
      isPrecondition
    };

    if (isParsedYamlDocAsJSON(recipe)) {
      node.isCustomRecipe = customRecipes.some((r) => r.name === recipe.name);
      node.name = recipe.displayName;
      node.val.id = recipe.name;
      node.val.name = recipe.displayName;
      node.val.description = recipe.description;
      node.val.tags = recipe?.tags || [];
      node.val.totalRecipes = recipe?.recipeList?.length || 0;
      node.val.options = [];

      await recursivelyTraverseSubRecipes(
        nodeId,
        recipe?.preconditions || [],
        traverse,
        {
          apolloContext,
          arePreconditions: true,
          customRecipes
        }
      );
    } else {
      node.isCustomRecipe = customRecipes.some((r) => r.name === recipe.id);
      node.name = recipe.name;
      node.val = { ...recipe, recipeList: undefined } as GraphNodeData['val'];
    }

    // Add current recipe as a node
    nodes.push(node);

    // If there's a parent, link current node to its parent
    if (parentId !== undefined) {
      links.push({ source: parentId, target: nodeId });
    }
    await recursivelyTraverseSubRecipes(
      nodeId,
      recipe?.recipeList || [],
      traverse,
      {
        apolloContext,
        arePreconditions: false,
        customRecipes
      }
    );
  }

  await traverse(rootRecipe);

  return { nodes, links };
};

const recursivelyTraverseSubRecipes = async (
  currentNodeId,
  recipes,
  traverseFunction,
  context: {
    apolloContext: ApolloContextValue;
    arePreconditions: boolean;
    customRecipes;
  }
) => {
  for (const subRecipe of recipes) {
    const isRecipeId = typeof subRecipe === 'string';
    const isFragment = isRecipeFragmentDetail(subRecipe);
    /**
     * subRecipe can be:
     * - string - "org.openrewrite.java.testing.mockito.MockUtilsToStatic"
     * - RecipeDetailsFragment - { id: "org.openrewrite.java.testing.mockito.MockUtilsToStatic", name: "Use static form of Mockito MockUtil" ...}
     * - parsedYaml - {"org.openrewrite.java.dependencies.UpgradeDependencyVersion": {"groupId":"org.mockito"}}
     */
    let recipeId;

    if (isRecipeId) {
      recipeId = subRecipe;
    } else if (isFragment) {
      recipeId = subRecipe.id;
    } else {
      recipeId = Object.keys(subRecipe)[0];
    }

    const customRecipe = context.customRecipes.find((r) => r.name === recipeId);

    if (customRecipe) {
      await traverseFunction(
        customRecipe,
        currentNodeId,
        context.arePreconditions
      );
    } else if (isFragment) {
      await traverseFunction(
        subRecipe,
        currentNodeId,
        context.arePreconditions
      );
    } else {
      // we need to get all the recipe and option meta data and then fill in option values
      const fragment = await getRecipeFragment(recipeId, context.apolloContext);
      const fragmentWithOptions = {
        ...fragment,
        options: fragment?.options?.map((option) => {
          const optionValues = subRecipe[recipeId];
          return {
            ...option,
            value: optionValues?.[option.name]
          };
        })
      };
      await traverseFunction(
        fragmentWithOptions,
        currentNodeId,
        context.arePreconditions
      );
    }
  }
};

const extractParsingErrorsFromYamlDocuments = (
  parsedYamlDocuments: Document.Parsed[]
): Error[] => {
  const errors: Error[] = [];

  for (const doc of parsedYamlDocuments) {
    if (doc.errors.length > 0) {
      doc.errors.forEach((error) => {
        errors.push(new Error(error.message));
      });
    }
  }

  return errors;
};

export const extractParsingErrorsAsString = (
  parsedYamlDocuments: Document.Parsed[]
): string => {
  const errors = extractParsingErrorsFromYamlDocuments(parsedYamlDocuments);
  return errors.map((error) => error.message).join('\n');
};

export type CreateYamlOptions = {
  /**
   * INTENT: we need to exclude the custom descriptions for more accurate AI
   * description generation
   */
  excludeCustomRecipeDescriptions?: boolean;
};

const createYamlDefaults = {
  excludeCustomRecipeDescriptions: false
};

export const createYamlFromNodesAndLinks = (
  nodes: GraphNodeData[],
  links: GraphLink[],
  options: CreateYamlOptions = {}
): string => {
  const { excludeCustomRecipeDescriptions } = {
    ...createYamlDefaults,
    ...options
  };
  const nodeMap: { [key: number]: GraphNodeData } = {};
  const childrenMap: { [key: number]: number[] } = {};

  nodes?.forEach((node) => {
    nodeMap[node.id] = node;
    childrenMap[node.id] = [];
  });

  links?.forEach((link) => {
    childrenMap[link.source].push(link.target);
  });

  function createYamlDocuments(): string[] {
    const documents: string[] = [];
    nodes?.forEach((node) => {
      const hasChildren = childrenMap[node.id].length > 0;
      if (node.isCustomRecipe && (hasChildren || node.id === 0)) {
        const recipeList = [];
        const preconditions = [];

        // single pass to sort into recipeList and preconditions lists
        childrenMap[node.id].forEach((childId) => {
          const childNode = nodeMap[childId];
          if (childNode?.isPrecondition) {
            preconditions.push(
              mapRecipeDetailsFragmentToDisplay(childNode?.val)
            );
          } else {
            recipeList.push(mapRecipeDetailsFragmentToDisplay(childNode?.val));
          }
        });

        const doc = {
          type: 'specs.openrewrite.org/v1beta/recipe',
          name: node?.val?.id,
          displayName: node?.val?.name,
          description: node?.val?.description ?? '',
          preconditions: preconditions.length > 0 ? preconditions : undefined,
          recipeList
        };

        if (excludeCustomRecipeDescriptions && node.isCustomRecipe) {
          doc.description = '';
        }

        documents.push(stringify(doc));
      }
    });
    return documents;
  }

  const yamlDocuments = createYamlDocuments();
  return yamlDocuments.join('---\n');
};
