// Builder v2
export const BUILDER_DEFAULT_RIGHT_SIDEBAR_WIDTH = '32%';
export const BUILDER_TOP_BAR_HEIGHT = 45;
export const BUILDER_RIGHT_PANEL_ID = 'builder-right-panel';

export const BUILDER_COLORS = {
  ORB_SHADER_DARK_COLOR: '#7686d6',
  ORB_SHADER_LIGHT_COLOR: '#d6c5f7',
  ORB_SHADER_DARK_COLOR__SELECTED: '#3164c9',
  ORB_SHADER_LIGHT_COLOR__SELECTED: '#2E42FF',
  ORB_SHADER_DARK_COLOR__HOVERED: '#3d7b6f',
  ORB_SHADER_LIGHT_COLOR__HOVERED: '#56dcb4',
  ORB_SHADER_DARK_COLOR__MATCHED: '#47b19c',
  ORB_SHADER_LIGHT_COLOR__MATCHED: '#bee9dc',
  SELECTED_GLOW_COLOR: '#e9ea95',
  BADGE_PRECONDITION_COLOR: '#F8ABD6',
  BADGE_OPTIONS_COLOR: '#E0E0E0',
  ORB_SHADER_DARK_COLOR__PRECONDITION: '#DB4197',
  ORB_SHADER_LIGHT_COLOR__PRECONDITION: '#ffc2f2',
  LINK_COLOR: '#74765b',
  SELECTED_LINK_COLOR: '#837620',
  BACKGROUND_COLOR: '#31323f'
};
